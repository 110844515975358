<template>
    <div class="onboarding">
        <div class="content">
            <div class="container">
                <form-wizard ref="onboarding_wizard">
                    <tab-content>
                        <div class="set-up-profile">
                            <div class="onboarding-navigation">
                                <div class="step-bullets">
                                    <div class="bullet active" />
                                    <div class="bullet" />
                                    <div class="bullet" />
                                    <div class="bullet" />
                                </div>
                                <button
                                    class="btn btn-primary next-button"
                                    @click="submitProfile"
                                >
                                    Next
                                </button>
                            </div>
                            <h1>Let’s set up your <span>profile</span></h1>
                            <profile-form ref="profile_form" />
                        </div>
                    </tab-content>
                    <tab-content>
                        <div class="following-creators">
                            <div class="onboarding-navigation">
                                <div class="step-bullets">
                                    <div class="bullet" />
                                    <div class="bullet active" />
                                    <div class="bullet" />
                                    <div class="bullet" />
                                </div>
                                <button
                                    :class="['btn btn-primary next-button not-allowed', {'disable-next': !creatorsFollowing}]"
                                    :disabled="!creatorsFollowing"
                                    @click="$refs.onboarding_wizard.nextTab()"
                                >
                                    Next
                                </button>
                            </div>
                            <h1>Pick at least 4 of our top <br class="d-none d-sm-block"><span>Creators</span> to follow</h1>
                            <div class="creators">
                                <template v-for="(creator, index) in onboarding.creators">
                                    <creator-card
                                        :key="creator.id"
                                        :creator="creator"
                                        @toggled-follow="(is_following) => onboarding.creators[index].is_following = is_following"
                                    />
                                </template>
                            </div>
                        </div>
                    </tab-content>
                    <tab-content>
                        <div class="following-topics">
                            <div class="onboarding-navigation">
                                <div class="step-bullets">
                                    <div class="bullet" />
                                    <div class="bullet" />
                                    <div class="bullet active" />
                                    <div class="bullet" />
                                </div>
                                <button
                                    :class="['btn btn-primary next-button not-allowed', {'disable-next': !topicsFollowing}]"
                                    :disabled="!topicsFollowing"
                                    @click="$refs.onboarding_wizard.nextTab()"
                                >
                                    Next
                                </button>
                            </div>
                            <h1>Customize you feed by following 4 of <br class="d-none d-sm-block">your favorite <span>Topics!</span></h1>
                            <div class="topics">
                                <template v-for="(topic, index) in onboarding.topics">
                                    <topic-card
                                        :key="topic.id"
                                        v-masonry-tile
                                        class="item"
                                        :topic="topic"
                                        @toggled-follow="(is_following) => onboarding.topics[index].is_follow = is_following"
                                    />
                                </template>
                            </div>
                        </div>
                    </tab-content>
                    <tab-content>
                        <div class="following-boards">
                            <div class="onboarding-navigation">
                                <div class="step-bullets">
                                    <div class="bullet" />
                                    <div class="bullet" />
                                    <div class="bullet" />
                                    <div class="bullet active" />
                                </div>
                                <button
                                    class="btn btn-primary next-button"
                                    @click="showIntroModal"
                                >
                                    Next
                                </button>
                            </div>
                            <h1>Go deeper by following <br class="d-none d-sm-block">our top <span>Boards!</span></h1>
                            <div class="boards">
                                <div class="row">
                                    <div v-for="(board, index) in onboarding.boards" :key="board.id" class="col-12 col-md-6">
                                        <board-card
                                            v-masonry-tile
                                            class="item"
                                            :board="board"
                                            @toggled-follow="(is_followed) => onboarding.boards[index].is_followed = is_followed"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab-content>
                </form-wizard>
            </div>
        </div>
    </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";

export default {
    name: "OnboardingProcess",
    components: {
        FormWizard,
        TabContent,
        CreatorCard: () => import(/* webpackChunkName: "creator-card" */ "./creator-card"),
        ProfileForm: () => import(/* webpackChunkName: "profile-form" */ "@c/organisms/profile-form"),
        TopicCard: () => import(/* webpackChunkName: "topic-card" */ "@/components/molecules/topic-card/"),
        BoardCard: () => import(/* webpackChunkName: "board-card" */ "@/components/molecules/board-card.vue")
    },
    data() {
        return {
            onboarding: {
                creators: [],
                topics: [],
                boards: []
            }
        };
    },
    computed: {
        creatorsFollowing() {
            return this.onboarding.creators.filter(creator => creator.is_following).length >= 4;
        },
        topicsFollowing() {
            return this.onboarding.topics.filter(topic => topic.is_follow).length >= 4;
        }
    },
    created() {
        this.getOnboarding();
    },
    methods: {
        showIntroModal() {
            this.$store.dispatch("Application/toggleOnboarding", false);
            this.$store.dispatch("Application/isReloadFollowing", true);
            this.$modal.show("intro-modal");
        },
        getOnboarding() {
            Object.keys(this.onboarding).forEach(category => {
                axiosV2.get(`/onboarding?type=${category}`).then(({ data }) => {
                    this.onboarding[category] = data;
                }).catch(error => {
                    console.log(error.response.data.message);
                });
            })
        },
        submitProfile() {
            this.$refs.profile_form.submitProfile();
            this.$refs.onboarding_wizard.nextTab();
        }
    }
}
</script>

<style lang="scss" scoped>
.onboarding {
    
    .disable-next {
        cursor: not-allowed;
    }

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    overflow-y: hidden;

    .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #0F0F10;
        padding-top: 50px;
        padding-bottom: 100px;
        overflow-y: auto;

        .vue-form-wizard {
            padding: 0;

            /deep/ .wizard-header,
            /deep/ .wizard-progress-with-circle,
            /deep/ .wizard-nav.wizard-nav-pills,
            /deep/ .wizard-card-footer {
                display: none;
            }

            /deep/ .wizard-tab-content {
                padding: 0;
                display: flex;

                .wizard-tab-container {
                    display: block;
                    width: 100%;
                    animation: fadeInRight 0.3s;
                }
            }
        }

        .container {
            .onboarding-navigation {
                margin-bottom: 30px;
                display: flex;
                justify-content: space-between;
                position: relative;

                .step-bullets {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    display: flex;

                    .bullet {
                        width: 7px;
                        height: 7px;
                        background-color: #242426;
                        margin-left: 7px;
                        border-radius: 100%;

                        &:first-child {
                            margin-left: 0;
                        }

                        &.active {
                            background-color: white;
                        }
                    }
                }

                .skip-button {
                    color: white;
                    padding-left: 0;

                    &:focus {
                        outline: 0;
                        box-shadow: none;
                    }
                }

                .next-button {
                    margin-left: auto;
                }
            }

            h1 {
                text-align: center;
                font-weight: 600;
                margin-bottom: 100px;

                @media(max-width: $md) {
                    margin-bottom: 30px;
                }

                span {
                    color: $primary-color;
                }
            }

            .set-up-profile {
                .profile-form {
                    max-width: 400px;
                    margin: 90px auto 0 auto;

                    /deep/ .profile-cover {
                        display: none;
                    }

                    /deep/ .form-content {
                        margin-top: 0;

                        .profile-picture {
                            margin: 0 auto 40px auto;
                        }
                    }
                }
            }

            .following-creators,
            .following-topics,
            .following-boards {
                .creators,
                .topics,
                .boards {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .topic-card {
                        margin-bottom: 25px;

                        @media(min-width: $md) {
                            width: 500px;
                        }
                    }

                    .board-card {
                        margin: 0 auto;
                        margin-bottom: 25px;
                    }
                }
            }

            .following-boards {
                .boards {
                    margin: 0 auto;
                    max-width: 570px;
                }
            }
        }
    }
}
</style>
